/*
  We have to manually render the Related Items for the Vehicle Galleries because the gallery PDP is rendered by a script.
  This script is loaded after our script, no matter what. When our script loads, there is no HTML yet.
  It is easier to render it ourselves than to explain everything to the client and ask them to include it in their script.
*/

const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const renderRelatedItems = (section, facets, items) => {
  const relatedLink = `https://www.threepiece.us/search/?${facets
    .filter((f) => f.Selection.length > 0)
    .map((f) => `${f.FieldName}/${f.Selection[0].Term}`)
    .join('/')}`;

  const relatedItems = items.map((item) => {
    const {
      custom_url: url,
      name,
      price: rawPrice,
      regular_price: rawRegularPrice,
      image_standard: rawImage,
      out_of_stock: isOutOfStock,
      on_sale: isOnSale,
    } = item;

    const image = rawImage ?? 'https://client.convermax.com/static/img/noimage.png';
    const price = priceFormatter.format(rawPrice);
    const regularPrice = rawRegularPrice ? priceFormatter.format(rawRegularPrice) : '';

    return `
      <div class="product">
        <article class="card">
          <figure class="card-figure">
            <div class="halo-badges halo-badges-right">
              ${
                !isOutOfStock && isOnSale
                  ? `<div class="sale-badge halo-badge">
                      <span class="text">-${Math.floor(((Math.round(regularPrice * 100) - Math.round(price * 100)) / regularPrice).toFixed(2))}%</span>
                    </div>`
                  : ''
              }
              ${
                isOutOfStock
                  ? `<div class="soldOut-badge halo-badge">
                      <span class="text">Sold out</span>
                    </div>`
                  : ''
              }
            </div>
            <a href="${url}" class="card-figure__link" aria-label="${name}, ${price}">
              <div class="card-img-container">
                <img class="card-image lazyautosizes lazyloaded" data-sizes="auto" src="${image}" data-src="${image}" alt="${name}" title="${name}" sizes="273px">
              </div>
            </a>
          </figure>
          <div class="card-body">
            <div>
              <h4 class="card-title">
                <a aria-label="${name}, ${price}" href="${url}">${name}</a>
              </h4>
              <div class="card-text card-price" data-test-info-type="price">
                <div class="price-section price-section--withoutTax">
                  <span class="price price--withoutTax">${price}</span>
                  <span rt-if="${isOnSale}" class="price-section price price--non-sale non-sale-price--withoutTax">
                    ${regularPrice}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>`;
  });

  const relatedItemsContainer = window.document.createElement('div');
  relatedItemsContainer.classList.add('cm_related-products');
  relatedItemsContainer.innerHTML = `
    <div class="cm_related-products-title-container">
      <h2 class="cm_related-products-title">
        Featured Products
        <a class="cm_related-products_search-link" href="${relatedLink}">View All</a>
      </h2>
    </div>
    <div class="cm_related-products-list cmRepeater_items">
      ${relatedItems.join('')}
    </div>`;

  section.appendChild(relatedItemsContainer);
};

export const renderGalleryRelatedItems = (itemId) => {
  if (!itemId) {
    return;
  }

  const intervalId = setInterval(() => {
    if (window.document.querySelector('#vehicle-gallery-page')) {
      clearInterval(intervalId);

      [
        ['vehiclerelated', '#vehicle-gallery-page .vehicle-tab'],
        ['wheelrelated', '#vehicle-gallery-page .wheel-tab'],
        ['tirerelated', '#vehicle-gallery-page .tire-tab'],
      ].forEach(([extra, selector]) => {
        const section = window.document.querySelector(selector);

        if (!section) {
          return;
        }

        window
          .fetch(
            `https://threepieceus.myconvermax.com/search.json?pagesize=2&extra.itemid=${itemId}&extra.mode=RelatedItems&extra.${extra}=true`,
            { method: 'GET' },
          )
          .then((response) => {
            if (response?.ok) {
              return response.text();
            }

            throw new Error(
              'Something went wrong with Featured Items request. If this error persists, please notify Convermax.',
            );
          })
          .then((responseText) => {
            const { Facets, Items } = JSON.parse(responseText);
            if (!Items?.length) {
              return;
            }

            renderRelatedItems(section, Facets, Items);
          });
      });
    }
  }, 500);

  setTimeout(() => clearInterval(intervalId), 10000);
};
