
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function mountedPackageDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', { 'className': 'cm_dialog-title-container' }, _createElement('h2', { 'className': 'cm_dialog-title' }, 'Finish Your Installation Kit'), _createElement('div', {
        'onClick': e => e.target.closest('#cm_MountedPackageDialog').style.display = 'none',
        'className': 'close',
        'data-cm-role': 'close'
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'dialog-content' }, [this.MountedPackageItems(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'product' }, _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('div', { 'className': 'halo-badges halo-badges-right' }, !this.out_of_stock && this.on_sale ? _createElement('div', {
                            'className': 'sale-badge halo-badge',
                            'key': '138'
                        }, _createElement('span', { 'className': 'text' }, '-', this.calcDiscount(this.price, this.regular_price), '%')) : null, this.out_of_stock ? _createElement('div', {
                            'className': 'soldOut-badge halo-badge',
                            'key': '338'
                        }, _createElement('span', { 'className': 'text' }, 'Sold out')) : null), _createElement('a', {
                            'href': this.custom_url,
                            'className': 'card-figure__link',
                            'aria-label': `${ this.removeHTML(this.name) }, ${ this.formatPrice(this.price) }`
                        }, _createElement('img', {
                            'className': 'card-image lazyautosizes lazyloaded',
                            'data-sizes': 'auto',
                            'src': this.image_standard,
                            'data-src': this.image_standard,
                            'alt': this.removeHTML(this.name),
                            'title': this.removeHTML(this.name),
                            'sizes': '273px',
                            'onError': this.onImageError
                        }))), _createElement('div', { 'className': 'card-body' }, _createElement('h4', { 'className': 'card-title' }, _createElement('a', Object.assign({}, {
                            'aria-label': `${ this.removeHTML(this.name) }, ${ this.formatPrice(this.price) }`,
                            'href': this.custom_url
                        }, { dangerouslySetInnerHTML: { __html: this.name } }))), _createElement('div', {
                            'className': 'card-text card-price',
                            'data-test-info-type': 'price'
                        }, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', { 'className': 'price price--withoutTax' }, this.formatPrice(this.price)), this.on_sale ? _createElement('span', {
                            'className': 'price-section price price--non-sale non-sale-price--withoutTax',
                            'key': '1390'
                        }, '\n            ', this.formatPrice(this.regular_price), '\n          ') : null)), !this.has_options && !this.out_of_stock ? _createElement('div', {
                            'className': 'card-figcaption-buttons',
                            'key': '1592'
                        }, _createElement('a', {
                            'onClick': globalThis.Convermax.redirectToTiresCategory(this.id),
                            'href': '/cart.php?action=add&product_id=' + this.id + '&qty=' + window.Convermax.getTiresQtyForPackage(),
                            'data-event-type': 'product-click',
                            'className': 'button button--small card-figcaption-button add-to-cart'
                        }, '\n          Add to cart\n        ')) : null, this.has_options && !this.out_of_stock ? _createElement('a', {
                            'href': this.custom_url,
                            'data-event-type': 'product-click',
                            'data-button-type': 'add-cart',
                            'className': 'button button--small card-figcaption-button halo-add-to-cart',
                            'data-product-id': this.id,
                            'key': '2018'
                        }, '\n        Choose Options\n      ') : null, this.out_of_stock ? _createElement('a', {
                            'disbled': 'true',
                            'className': 'button button--small card-figcaption-button halo-add-to-cart',
                            'data-product-id': this.id,
                            'key': '2299'
                        }, '\n        Out of stock\n      ') : null)));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_MountedPackageItems' }, _createElement('div', { 'className': 'cm_mounted-products' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_mounted-products-list cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ])));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]));
}
        export const componentNames = ["cm:MountedPackageItems"]